import cx from 'classnames'
import classes from './styles.module.scss'
import type { PictureProps } from './Picture.types'
import { generatePictureByType } from './utils/generatePictureByType'

export function Picture(props: PictureProps) {
  const { src, alt, className, loading, type, priority } = props
  const { sizes, webp, fallback, width, height } = generatePictureByType({
    imageType: type,
    src,
  })

  return (
    <>
      <picture>
        {!!webp.length && <source srcSet={webp} type='image/webp' sizes={sizes} />}
        <img
          src={fallback}
          alt={alt}
          className={cx(classes.image, className)}
          width={width}
          height={height}
          loading={!priority ? loading : 'eager'}
          decoding='async'
          fetchPriority={priority ? 'high' : undefined}
        />
      </picture>
    </>
  )
}

import { captureMessage } from '@sentry/nextjs'
import { serviceDependencies } from 'src/services'
import type { CategoryCode } from '@patrianna/shared-patrianna-types/services/productCategories'
import type { GameProduct, SwimlaneCategory } from '@patrianna/shared-patrianna-types/store/GameModule'
import type { GetProductLayoutResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import { isBrowser } from '@patrianna/shared-utils/helpers'
import ROUTES from 'temp/routes.json'
import type { PragmaticJackpot } from 'src/store/modules/pragmaticJackpots/types'
import { arrayFilterNullable } from 'utils/filterNullable'

// TODO: use @patrianna/shared-utils/verifyGameForAllowedChars after @patrianna/shared-utils will be updated to 4.4.59
function verifyGameForAllowedChars(game: GameProduct) {
  if (encodeURIComponent(game.route) !== game.route) {
    throw new Error(`Not valid route -  "${game.route}"`)
  }
}

export async function getAllGames(cookies?: string, headers?: Record<string, string>): Promise<GameProduct[]> {
  const path = typeof window === 'undefined' ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/get-all-products?brandName=${process.env.BRAND_NAME}`

  let response
  try {
    response = await serviceDependencies.fetch(url, null, { method: 'get', cookies }, headers)
  } catch (error) {
    // [FYI] log into JS console only, we do not log network errors into Sentry
    console.log('getAllGames Network error', url, error)

    return []
  }
  try {
    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`)
    }
    const result = await response.json()

    return arrayFilterNullable(result?.products)
  } catch (error) {
    console.log('getAllGames error', url, error)
    captureMessage(`getAllGames url error ${error?.message}`)

    return []
  }
}

const fetchCategories = async (
  url: string,
  cookies?: string,
  headers?: Record<string, string>
): Promise<SwimlaneCategory[]> => {
  const response = await serviceDependencies.fetch(url, null, { method: 'get', cookies }, headers)
  const result = await response.json()

  return arrayFilterNullable(result?.categories)
}

// use map to cache categories request for build for 1h
const getCachedCategories = (() => {
  let timer: ReturnType<typeof setTimeout>
  const categoriesCache = new Map<string, Awaited<ReturnType<typeof fetchCategories>>>()

  return async (url: string, cookies?: string, headers?: Record<string, string>): Promise<SwimlaneCategory[]> => {
    if (!timer) {
      // temporary decision to clear cache after 1h (to make sure that every build will have their own cache)
      timer = setTimeout(() => {
        categoriesCache.clear()
        timer = null
      }, 60 * 60 * 1000) // 3600 seconds
    }

    let cachedUrl = categoriesCache.get(url)
    if (!cachedUrl) {
      console.log('\nSTATIC PAGE BUILD LOG: CACHE MISS FOR URL: ', url)
      const newCategories = await fetchCategories(url, cookies, headers)
      categoriesCache.set(url, newCategories)
    }

    return categoriesCache.get(url)
  }
})()

export async function getAllCategories(
  cookies?: string,
  headers?: Record<string, string>,
  useCache = false
): Promise<SwimlaneCategory[]> {
  const path = typeof window === 'undefined' ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/product-layout?brandName=${process.env.BRAND_NAME}`

  try {
    return useCache ? getCachedCategories(url, cookies, headers) : fetchCategories(url, cookies, headers)
  } catch (error) {
    console.error(`Fetch ${getAllCategories.name} function error: `, 'URL', url, 'Error', error?.message)

    return []
  }
}

export async function getPragmaticJackpots(currency: 'SC' | 'GC'): Promise<PragmaticJackpot[]> {
  const url = `${process.env.PRAGMATIC_JACKPOTS}?currency=${currency}`

  try {
    const response = await serviceDependencies.fetch(url, null, {
      method: 'get',
    })
    const result = await response.json()

    return arrayFilterNullable(result?.jackpots)
  } catch (error) {
    console.error(`Fetch ${getPragmaticJackpots.name} function error: `, 'URL', url, 'Error', error?.message)

    return []
  }
}

export async function getCategoryByRoute(categoryRoute: string, cookies?: string): Promise<SwimlaneCategory | null> {
  const path = typeof window === 'undefined' ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/product-layout?brandName=${process.env.BRAND_NAME}`

  try {
    const response = await serviceDependencies.fetch(url, null, { method: 'get', cookies })
    const result = await response.json()
    const category = result?.categories.find(
      (i: SwimlaneCategory) => i.route === `${ROUTES.GAMES_SLOTS}/${categoryRoute ? `${categoryRoute}/` : ''}`
    )

    return category ?? null
  } catch (error) {
    console.error(`Fetch ${getCategoryByRoute.name} function error: `, 'URL', url, 'Error', error?.message)

    return null
  }
}

export async function getGameByCode(code: string): Promise<GameProduct> {
  const path = typeof window === 'undefined' ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/get-product?brandName=${process.env.BRAND_NAME}&code=${code}`
  try {
    const response = await serviceDependencies.fetch(url, null, { method: 'get' })
    if (!response.ok) return null

    const game = await response.json()

    verifyGameForAllowedChars(game)

    return game
  } catch (error) {
    console.log('Fetch getGameByCode function error: ', 'URL', url, 'Error', error?.message)
    console.error(`Fetch ${getGameByCode.name} function error: `, 'URL', url, 'Error', error?.message)

    return null
  }
}

export async function getGameByRoute(route: string, cookies?: string): Promise<GameProduct> {
  const isServer = !isBrowser()
  const showAll = isServer ? '&showAll=true' : ''
  const path = isServer ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/get-product?brandName=${process.env.BRAND_NAME}&route=${route}${showAll}`
  const response = await serviceDependencies.fetch(url, null, { method: 'get', cookies })
  const game = await response.json()

  verifyGameForAllowedChars(game)

  return game
}

export async function getGamesByCategoryCode(
  placements: {
    productCategoryCode: CategoryCode
    gameId?: string
  }[],
  cookies?: string,
  headers?: Record<string, string>
): Promise<GetProductLayoutResponse> {
  const path = !isBrowser() ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/product-category?brandName=${process.env.BRAND_NAME}`
  const body = { placements }

  try {
    const data = await serviceDependencies.fetch(url, body, { cookies }, headers)
    if (!data.ok) return null

    return data.json()
  } catch (error) {
    console.error(`Fetch ${getGamesByCategoryCode.name} function error: `, 'URL', url, 'Error', error?.message)

    return null
  }
}

export async function getProviderURL({ apiUrl, body }: any) {
  const fetchLaunchData = async () => {
    return await serviceDependencies.fetch(apiUrl as string, body, { method: 'POST' })
  }

  try {
    const response = await fetchLaunchData()
    const { gameUrl } = await response.json()

    if (gameUrl) {
      return gameUrl
    }
  } catch (error) {
    console.log({ error })
  }

  return ''
}

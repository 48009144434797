import { useAppSelector } from 'store/hooks'
import { getUserAccountIdSelector } from 'store/modules/user/selectors'
import CopyToClipboardInput from 'components/CopyToClipboardInput'
import { generateShareUrlWithUserId } from 'utils/url'
import classes from './styles.module.scss'

function ReferFriendLink() {
  const accountId = useAppSelector(getUserAccountIdSelector)
  const shareUrl = generateShareUrlWithUserId(accountId)

  return (
    <section className={classes.root}>
      <CopyToClipboardInput inputText={shareUrl} />
    </section>
  )
}

export default ReferFriendLink

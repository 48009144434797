import { createSelector } from '@reduxjs/toolkit'
import type { ReduxState } from 'src/store/types'
import { hasGameWithCode } from 'store/modules/categoryGames/utils'
import { getVisibleGames } from 'pageContainers/SearchPage/utils/getVisibleGames'

const getCategoryGames = (state: ReduxState) => state.categoryGames

const getFavoriteGames = (state: ReduxState) => getCategoryGames(state).favoriteGames
export const getRecentlyPlayedGames = (state: ReduxState) => getCategoryGames(state).recentlyPlayedGames
export const getRecommendedGames = (state: ReduxState) => getCategoryGames(state).recommendedGames
export const getRecentlyPlayedGamesIsLoaded = (state: ReduxState) => getCategoryGames(state).isSuccess

export const getFavoriteGamesSelector = createSelector(getFavoriteGames, (favoriteGames) => favoriteGames)

export const isCategoryGamesLoadedSelector = createSelector(
  getCategoryGames,
  (favoriteGames) => favoriteGames.isSuccess
)

export const isFavoriteGameSelector = createSelector(
  getFavoriteGamesSelector,
  (_: ReduxState, gameCode: string) => gameCode,
  (games, gameCode) => hasGameWithCode(games, gameCode)
)

export const getRecentlyPlayedGamesSelector = createSelector(
  getRecentlyPlayedGames,
  (recentlyPlayedGames) => recentlyPlayedGames
)

export const getRecentlyPlayedGamesWithLengthSelector = createSelector(
  getRecentlyPlayedGames,
  (_: ReduxState, gameTilesInRow: number) => gameTilesInRow,
  (recentlyPlayedGames, gameTilesInRow) =>
    recentlyPlayedGames ? getVisibleGames(recentlyPlayedGames, gameTilesInRow) : []
)

export const getRecentlyPlayedGamesIsLoadedSelector = createSelector(
  getRecentlyPlayedGamesIsLoaded,
  (recentlyPlayedGamesIsLoaded) => recentlyPlayedGamesIsLoaded
)

export const isRecentlyPlayedGameSelector = createSelector(
  getRecentlyPlayedGamesSelector,
  (_: ReduxState, gameCode: string) => gameCode,
  (games, gameCode) => hasGameWithCode(games, gameCode)
)

export const isMostRecentlyPlayedGameSelector = createSelector(
  getRecentlyPlayedGamesSelector,
  (_: ReduxState, gameCode: string) => gameCode,
  (games, gameCode) => games?.[0]?.code === gameCode
)

export const getRecommendedGamesSelector = createSelector(getRecommendedGames, (recommendedGames) => recommendedGames)

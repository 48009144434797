'use client'

import { BuilderComponent } from '@builder.io/react'
import type { BuilderContent } from '@patrianna/shared-patrianna-types/builder'

import RegisteredBuilderCustomComponents from 'components/BuildersCustomComponents'

// register custom component to builder
RegisteredBuilderCustomComponents()

type Props = {
  content: BuilderContent
}

export function BuilderPageContent({ content }: Props) {
  return <BuilderComponent content={content} model={process.env.BUILDER_PAGE_MODEL} />
}

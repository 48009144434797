import Skeleton from 'app/components/Skeleton'
import classes from './styles.module.scss'

type Props = {
  isPromotionsPage?: boolean
}

export default function SpecialOffersSkeleton({ isPromotionsPage }: Props) {
  return (
    <>
      {!isPromotionsPage && <div className={classes.titleOffersFallback} />}
      <div className={classes.skeleton}>
        <Skeleton className={classes.skeletonItem} length={3} />
      </div>
    </>
  )
}

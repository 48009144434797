'use client'

import cx from 'classnames'
import { IconButton } from '@patrianna/core-components'
import type { FavouriteButtonProps } from './FavoriteButton.types'
import { useFavoriteButton } from './hooks/useFavoriteButton'

import classes from './styles.module.scss'

export default function FavoriteButton({ gameProduct, className, hasCaption }: FavouriteButtonProps) {
  const { favoriteBtnTitle, favoriteIconPath, shouldNotRenderBtn, onFavoriteClick } = useFavoriteButton(gameProduct)

  if (shouldNotRenderBtn) return null

  return (
    <IconButton
      className={cx(classes.favoriteButton, className, {
        [classes.hasCaption]: hasCaption,
      })}
      onClick={onFavoriteClick}
      data-test='favourite-button'
    >
      <img src={favoriteIconPath} width={16} height={16} alt='favorites icon' />
      {hasCaption ? <div className={classes.caption}>{favoriteBtnTitle}</div> : null}
    </IconButton>
  )
}

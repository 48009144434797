import { useRouter } from 'app/context/navigation'
import { useMediaQuery } from '@patrianna/shared-hooks'
import { buyShopOffer } from '@patrianna-payments/shared-store/payments/store/middleware-actions'
import type { FeatureProps } from '@patrianna-payments/shared-store/payments/types'
import { intlnumberFormat } from '@patrianna/shared-utils/numbers'
import { useAppDispatch } from 'store/hooks'
import Button from 'components/Button'
import Img from 'components/Img'
import OldPrice from 'components/ShopTile/components/OldPrice'
import classes from './styles.module.scss'
import type { ShopTileProps } from '../../types'
import { useTranslation } from 'context/translationContext'
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter'

const DEFAULT_OFFER_ICON_URL = `${process.env.IMG_ORIGIN}/gold-coins-page/special-discount-1.png`

export default function SpecialShopTile({
  offer,
  scEnabled,
  disabled,
  feature = 'in_store',
}: ShopTileProps & { feature?: FeatureProps }) {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery('md')
  const t = useTranslation()
  const scActualPrice = offer?.sweepstakeFirstOffer || offer?.sweepstakeMoney
  const gcActualPrice = offer?.goldFistOffer || offer?.goldMoney
  const { currencyFormatter } = useCurrencyFormatter()

  const scBenefits = Boolean(scActualPrice && scEnabled)

  const imageUrl = offer?.specialOfferUrl || DEFAULT_OFFER_ICON_URL

  return (
    <div className={classes.root}>
      <Img src={imageUrl} className={classes.specialIcon} fallbackImg={DEFAULT_OFFER_ICON_URL} alt='Coin icon' />

      <h5 className={classes.title}>
        {intlnumberFormat(gcActualPrice)} {t('common.goldCoins')}
      </h5>

      <Button
        data-test={`pay-button-${offer.code}`}
        onClick={() => dispatch(buyShopOffer({ offer, feature, routerPush: router.push as () => void }))}
        className={classes.button}
        disabled={disabled}
      >
        {offer?.oldPrice && <OldPrice price={offer?.oldPrice} />}
        {currencyFormatter(offer.price)}
      </Button>

      <div className={classes.benefits}>
        <div className={classes.features}>
          <div className={classes.feature}>
            <div className={classes.currencyIcon}>
              <img src={`${process.env.IMG_ORIGIN}/menu/chat.svg?v=1`} alt='Live Chat Access' />
            </div>

            <span className={classes.featureText}>{t('common.live_chat_access')}</span>
          </div>

          {scBenefits && (
            <div className={classes.feature}>
              <div className={classes.currencyIcon}>
                <img src={`${process.env.IMG_ORIGIN}/menu/money22.svg?v=3`} alt='Currency icon' />
              </div>

              <span className={classes.featureText}>
                {t('common.free')} {t('common.sweepstakes_coins_sc')}{' '}
                {intlnumberFormat(scActualPrice, 'en-US', {
                  minimumFractionDigits: 2,
                })}
              </span>
            </div>
          )}

          <div className={classes.feature}>
            <div className={classes.currencyIcon}>
              <img src={`${process.env.IMG_ORIGIN}/menu/slots-gold.svg?v=3`} alt='Slots' />
            </div>

            <span className={classes.featureText}>
              {isMobile ? t('common.exclusive_games') : t('common.exclusive_gc_games')}
            </span>
          </div>
        </div>
        <div className={classes.features}>
          <div className={classes.feature}>
            <div className={classes.currencyIcon}>
              <img src={`${process.env.IMG_ORIGIN}/menu/chat.svg?v=1`} alt='Live Chat Access' />
            </div>

            <span className={classes.featureText}>{t('common.live_chat_access')}</span>
          </div>

          {scBenefits && (
            <div className={classes.feature}>
              <div className={classes.currencyIcon}>
                <img src={`${process.env.IMG_ORIGIN}/menu/money22.svg?v=3`} alt='Currency icon' />
              </div>

              <span className={classes.featureText}>
                {t('common.free')} {t('common.sweepstakes_coins_sc')}{' '}
                {intlnumberFormat(scActualPrice, 'en-US', {
                  minimumFractionDigits: 2,
                })}
              </span>
            </div>
          )}

          <div className={classes.feature}>
            <div className={classes.currencyIcon}>
              <img src={`${process.env.IMG_ORIGIN}/menu/slots-gold.svg?v=3`} alt='Slots' />
            </div>

            <span className={classes.featureText}>
              {isMobile ? t('common.exclusive_games') : t('common.exclusive_gc_games')}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

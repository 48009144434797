import type { Currencies } from '@patrianna/shared-patrianna-types/store/CurrenciesModule'
import type {
  GetAccountJackpotInfoRequest,
  GetJackpotInfoRequest,
  SaveJackpotOptInRequest,
} from '@patrianna/shared-patrianna-types/websocket/requests'
import type {
  GetAccountJackpotInfoResponse,
  GetJackpotInfoResponse,
  SaveJackpotOptInResponse,
} from '@patrianna/shared-patrianna-types/websocket/response'
import type { TypedThunk } from 'store/types'
import type { JackpotPrize } from 'store/types/JackpotsModule'
import { setDataToLocalStorage, SHOWN_SC_FREE_CONTRIBUTIONS_CODES } from 'utils/localStorage'
import { openDialog } from '../dialog/actions'
import { actions } from './slice'
import { getSweepstakeFreeContributionsSelector } from './selectors'

export const {
  setAccountJackpotInfo,
  setJackpotTotals,
  setJackpotPrize,
  setContibutionsJackpot,
  setJackpotUpdateInterval,
  patchJackpotTotals,
  addNewFreeContributionReward,
  updateFreeContributionsBalance,
  setJackpotOptInIsLoading,
} = actions

export const getAccountJackpotInfo =
  (): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const data: GetAccountJackpotInfoRequest = {
      type: 'jackpot.GetAccountJackpotInfoRequest',
    }

    gateway
      .emit<GetAccountJackpotInfoResponse>(data)
      .then((body) => {
        dispatch(setAccountJackpotInfo(body))
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })
  }

export const getJackpotTotals =
  (): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const data: GetJackpotInfoRequest = {
      type: 'jackpot.GetJackpotInfoRequest',
    }

    gateway
      .emit<GetJackpotInfoResponse>(data)
      .then((body) => {
        dispatch(setJackpotTotals(body.preferences))
        dispatch(setJackpotUpdateInterval(body.updateInterval))
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })
  }

export const saveJackpotOptIn =
  ({ optIn, currency, amount }: { optIn: boolean; currency: Currencies; amount?: number }): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const data: SaveJackpotOptInRequest = {
      type: 'jackpot.SaveJackpotOptInRequest',
      optIn,
      currency,
      ...((amount && { amount }) || {}),
    }

    gateway
      .emit<SaveJackpotOptInResponse>(data)
      .then(() => {
        dispatch(getAccountJackpotInfo())
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })
  }

export const openWonJackpotDialog =
  ({ amount, code, currency, jackpotId }: JackpotPrize): TypedThunk =>
  (dispatch) => {
    const modalName = currency === 'GC' ? 'JACKPOTS_GOLD_WON_DIALOG' : 'JACKPOTS_SWEEPSTAKE_WON_DIALOG'

    dispatch(openDialog({ modalName, dialogProps: { amount, code }, lightBackdropMode: true }))
    dispatch(setJackpotPrize({ amount, code, currency, jackpotId }))
  }

export const openSpinWheelDialog =
  ({ amount, code, currency, jackpotId, spinAmount }: JackpotPrize): TypedThunk =>
  (dispatch) => {
    const modalName = 'JACKPOTS_SPIN_WHEEL_DIALOG'

    dispatch(
      openDialog({ modalName, dialogProps: { amount, code, currency, jackpotId, spinAmount }, lightBackdropMode: true })
    )
  }

export const openWheelDialog =
  ({ amount, code, currency, jackpotId, spinAmount }: JackpotPrize): TypedThunk =>
  (dispatch) => {
    const modalName = 'JACKPOTS_WHEEL_DIALOG'

    dispatch(
      openDialog({ modalName, dialogProps: { amount, code, currency, jackpotId, spinAmount }, lightBackdropMode: true })
    )
  }

export const clearJackpotsContributions = (): TypedThunk => (dispatch) => {
  dispatch(setContibutionsJackpot([]))
}

export const setAllScFreeContributionsAsUserAware = (): TypedThunk => (dispatch, getState) => {
  const freeContributions = getSweepstakeFreeContributionsSelector(getState())
  dispatch(
    setDataToLocalStorage(
      SHOWN_SC_FREE_CONTRIBUTIONS_CODES,
      freeContributions.map((i) => i.code),
      false
    )
  )
}

import type { JackpotTotals } from 'store/types'

export const getMinContribute = (amount: number) => {
  // minimum contribution is 0.5% of the current amount
  // if (current amount - 0.5) < 50 then minimum contribution is 50
  const minPercentageOfAmount = (amount * 0.5) / 100

  return Math.trunc(minPercentageOfAmount < 50 ? 50 : minPercentageOfAmount)
}

export const getMaxContribute = (amount: number) => {
  // maximum contribution is 10% of the current amount
  // if (current amount - 10%) < 50 then maximum contribution is 50
  const maxPercentageOfAmount = (amount * 10) / 100

  return Math.trunc(maxPercentageOfAmount < 50 ? 50 : maxPercentageOfAmount)
}

export const jackpotDefaultValues: Array<JackpotTotals> = [
  { code: 'mini', amount: 0 },
  { code: 'minor', amount: 0 },
  { code: 'major', amount: 0 },
  { code: 'grand', amount: 0 },
]

export const jackpotSortOrder = jackpotDefaultValues.map(({ code }) => code)

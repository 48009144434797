'use client'

import { getLanguageHref } from 'app/utils/getLanguageHref'
import type { LinkProps } from 'next/link'
import NextLink from 'next/link'
import { useParams, usePathname } from 'next/navigation'
import type { ReactNode, MouseEvent } from 'react'
import NProgress from 'nprogress'
import ROUTES from 'temp/routes.json'
import { nextTick } from '@patrianna/shared-utils'
import React from 'react'
import useIsMobileApp from 'hooks/useIsMobileApp'

const handleDelayedExternalRedirect = (url: string | URL, target?: string): void => {
  setTimeout(() => {
    window.open(url, target)
  }, 0)
}

export type Props = {
  href: string
  children?: ReactNode
  className?: string
  target?: '_blank' | ''
  rel?: string
  title?: string
  language?: string
  // HACK for disabling yieldToMainCallback for payment dialog
  isDisabledYieldToMainCallback?: boolean
  role?: string
  onKeyDown?: (evt: React.KeyboardEvent<HTMLAnchorElement>) => void
} & LinkProps

export function Link(props: Props) {
  const { children, href, passHref, language, onClick, onKeyDown, isDisabledYieldToMainCallback, ...rest } = props

  const pathname = usePathname()
  const { locale } = useParams()
  const externalHref = href?.startsWith('http')
  const isMobileApp = useIsMobileApp()
  const languageHref = getLanguageHref({ href, language, locale })

  if (externalHref || isMobileApp) {
    // The default behavior for external links must be overridden with a
    // delayed redirect to process the next browser render before the navigation.
    // It results in a lower INP value
    const onExternalLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
      if (!isMobileApp) {
        e.preventDefault()
        handleDelayedExternalRedirect(href, props.target)
      }
    }

    return (
      <a href={href} onClick={onExternalLinkClick} {...rest}>
        {children}
      </a>
    )
  }

  function handleClick(event: MouseEvent<HTMLAnchorElement>) {
    const nextHref = languageHref === '/' ? ROUTES.HOME : languageHref

    if (pathname !== nextHref) {
      NProgress.configure({ showSpinner: false }).start()
    }

    if (onClick) {
      if (isDisabledYieldToMainCallback) {
        onClick(event)
      } else {
        nextTick(() => {
          onClick(event)
        })
      }
    }
  }

  return (
    <NextLink
      href={languageHref}
      passHref={passHref}
      prefetch={false}
      onClick={handleClick}
      onKeyDown={onKeyDown}
      {...rest}
    >
      {children}
    </NextLink>
  )
}

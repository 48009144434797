import type { TagsType, SwimlaneCategoryStyle } from '@patrianna/shared-patrianna-types/store/GameModule'
import cx from 'classnames'
import tagsConfig from 'temp/productTileTagsConfig.json'
import { NEW_TAG, SPIN_AND_WINS_TAG, EXCLUSIVE_TAG, TOURNAMENT_TAG } from './ProductTileTags.constants'

import classes from './ProductTileTags.module.scss'

export function getTagImage(tags: TagsType[] & 'HJR', tagSize?: SwimlaneCategoryStyle) {
  switch (true) {
    case tags.includes('PJP'):
      return {
        className: cx(classes.logoPragmaticJackpot, tagSize === 'large' && classes.logoPragmaticJackpotLarge),
        src: `${process.env.IMG_ORIGIN}/product-tile-tags/pragmatic-jackpot-tag.png`,
        alt: 'Jackpot Play',
      }
    case tags.includes('HJR'):
      return {
        className: cx(classes.logoHabaneroJackpot, tagSize === 'large' && classes.logoHabaneroJackpotLarge),
        src: `${process.env.IMG_ORIGIN}/product-tile-tags/jackpot-race.png`,
        alt: 'Jackpot Race',
      }
    default:
      return null
  }
}

export const isDefaultGameProductTags = (tags: TagsType[]) =>
  tagsConfig?.reduce<string[]>((acc, tagObj: { tag: TagsType; value: string }) => {
    if (tags.includes(tagObj.tag)) {
      acc.push(tagObj.value)
    }

    // show only two tags
    return acc.slice(0, 2)
  }, [])

export const getTagClassName = (tagName: string, tagIdx: number): string => {
  const defaultStyle = tagIdx === 0 ? classes.primaryColorTag : classes.secondaryColorTag

  switch (tagName) {
    case SPIN_AND_WINS_TAG:
      return classes.spinAndWinTag
    case NEW_TAG:
      return classes.primaryColorTag
    case EXCLUSIVE_TAG:
      return classes.exclusiveTag
    case TOURNAMENT_TAG:
      return classes.tournamentTag
    default:
      return defaultStyle
  }
}

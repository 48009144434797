import { useCopyClipboard } from '@patrianna/shared-hooks'
import cx from 'classnames'
import Button from 'components/Button'
import classes from './styles.module.scss'
import { useTranslation } from 'context/translationContext'
import { trackEvent } from 'config/analytic'
import { Show } from 'components/Show'
import { SharingNativeMobile } from 'components/SharingNativeMobile'
import useIsShareApiAvailable from 'hooks/useIsShareApiAvailable'

type Props = {
  inputText: string
  customEvent?: string
  className?: string
  linkHolderClassName?: string
  shareNative?: boolean
  hideInputText?: boolean
  customShareTextMobile?: string
  buttonsContainerClass?: string
}

export default function CopyToClipboardInput(props: Props) {
  const { customEvent, className, inputText, shareNative, customShareTextMobile, hideInputText } = props
  const [isCopied, setClipboard] = useCopyClipboard(inputText)
  const { isShareAPIAvailable } = useIsShareApiAvailable()
  const t = useTranslation()

  const isCopyButtonHidden = isShareAPIAvailable && !!customShareTextMobile

  const handleClick = () => {
    if (!isCopied) {
      trackEvent(customEvent || 'clicked_on_referfirend_copylink')
    }
    setClipboard()
  }

  return (
    <div className={cx(classes.root, className)}>
      <Show when={!isCopyButtonHidden}>
        <Button
          onClick={handleClick}
          variant='contained'
          size='small'
          className={classes.button}
          data-test='invite-friends-copy-link-button'
        >
          <Show when={!isCopied}>{t('common.copy_link')}</Show>
          <Show when={isCopied}>✓ {t('common.copied')}</Show>
        </Button>
      </Show>
      <Show when={shareNative}>
        <SharingNativeMobile
          text={customShareTextMobile || `${t('common.share_link_custom_text')}: ${inputText}`}
          className={classes.button}
        />
      </Show>

      {!hideInputText && <div className={classes.linkHolder}>{inputText}</div>}
    </div>
  )
}

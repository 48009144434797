import { useEffect } from 'react'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { allGamesSelector } from 'store/modules/games/selectors'
import { getAllProducts } from 'store/modules/games/actions'

export default function useGetProducts() {
  const dispatch = useAppDispatch()
  const allGames = useAppSelector(allGamesSelector)

  useEffect(() => {
    dispatch(getAllProducts())
  }, [])

  return allGames
}

import type { GameProduct, TagsType } from '@patrianna/shared-patrianna-types/store/GameModule'
import SlotsRow from 'src/pageContainers/Home/components/SlotsRow'
import useGetProducts from '../hooks/useGetProducts'

export type Props = {
  title: string
  tag: TagsType
}

export default function GamesRowByTagContainerWithScroll(props: Props) {
  const games = useGetProducts()
  const filteredGames = games?.filter((item: GameProduct) => item.tags.includes(props.tag))

  if (games.length) {
    return <SlotsRow games={filteredGames} title={props.title} type='default' isMobileUserAgent />
  }

  return null
}

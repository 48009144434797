import type { LegacyRef } from 'react'
import { forwardRef, useEffect } from 'react'
import cx from 'classnames'
import { usePrevious } from '@patrianna/shared-hooks'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { openDialog } from 'store/modules/dialog/actions'
import { getAcceptingDailyBonusSelector, getLoadingBonusesSelector } from 'store/modules/bonusesFlow/selectors'
import { useCountdown } from 'hooks/useCountdown'
import Button from 'components/Button'
import classes from './styles.module.scss'
import getAccountBonusesRequest from 'services/gateway/requests/getAccountBonuses'
import { useTranslation } from 'context/translationContext'
import CountdownIcon from 'components/GoldCoinGeneratorButton/Countdown/CountdownIcon'

type Props = {
  nextBonusDate: string | null
}

const DailyReward = forwardRef(function DailyReward({ nextBonusDate }: Props, ref: LegacyRef<HTMLDivElement>) {
  const isLoading = useAppSelector(getLoadingBonusesSelector)
  const acceptingDailyBonus = useAppSelector(getAcceptingDailyBonusSelector)
  const dispatch = useAppDispatch()
  const { countdown, pendingCountdown } = useCountdown(nextBonusDate)
  const prevCountdown = usePrevious(countdown)
  const t = useTranslation()

  useEffect(() => {
    if (prevCountdown && !countdown) {
      dispatch(getAccountBonusesRequest())
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [countdown])

  const openModalHandler = () => {
    dispatch(openDialog({ modalName: 'DAILY_REWARD_DIALOG' }))
  }

  return (
    <div ref={ref} className={classes.root}>
      <div className={classes.greenLabel}>
        <span>{t('common.daily_reward')}</span>
      </div>

      <img
        src={`${process.env.IMG_ORIGIN}/gold-coins-page/free-rewards-1.png`}
        className={cx(classes.specialIcon, classes.guy)}
        height='auto'
        alt='reward'
      />

      <h5 className={classes.title}>{t('common.free_rewards')}</h5>

      <Button
        data-test='open-daily-reward-button'
        onClick={openModalHandler}
        className={classes.button}
        disabled={!!countdown || isLoading || acceptingDailyBonus}
      >
        {countdown || acceptingDailyBonus ? (
          <div className={classes.countdown}>
            <div className={classes.countdownText}>{t('common.available_in')}</div>
            <div>{acceptingDailyBonus ? pendingCountdown : countdown}</div>
            <CountdownIcon className={classes.clockIcon} />
          </div>
        ) : (
          <span className={classes.claimBtn}>{t('common.claim_now')}</span>
        )}
      </Button>

      <div className={cx(classes.benefits, classes.dailyBenefits)}>
        <span className={classes.featureText}>{t('common.free_coins_once_a_day')}</span>
      </div>
    </div>
  )
})

export default DailyReward

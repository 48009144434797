import { useState } from 'react'
import Button from 'components/Button'
import { captureMessage } from '@sentry/nextjs'
import classes from './styles.module.scss'
import { useTranslation } from 'app/context/translations'
import cx from 'classnames'
import useIsShareApiAvailable from 'hooks/useIsShareApiAvailable'

type TSharingNativeMobile = {
  title?: string
  text?: string
  url?: string
  className?: string
}

export const SharingNativeMobile = ({ url, title, text, className }: TSharingNativeMobile) => {
  const { isShareAPIAvailable } = useIsShareApiAvailable()
  const [sharingIsActive, setSharingIsActive] = useState(false)
  const t = useTranslation()

  if (!isShareAPIAvailable) {
    return null
  }

  const handleClick = async () => {
    setSharingIsActive(true)
    const shareData = {
      ...(title && { title }),
      ...(text && { text }),
      ...(url && { url }),
    }

    try {
      await navigator.share(shareData)
    } catch (err) {
      if (!err.toString().includes('AbortError')) {
        captureMessage(`Error in SharingNativeMobile: ${err?.message}; Agent: ${navigator?.userAgent}`)
      }
    } finally {
      setSharingIsActive(false)
    }
  }

  return (
    <Button
      onClick={handleClick}
      variant='contained'
      size='small'
      className={cx(classes.shareButton, className)}
      disabled={sharingIsActive}
    >
      <img src={`${process.env.IMG_ORIGIN}/dialogs/share.png`} alt='Share' />
      {t('common.share_link')}
    </Button>
  )
}

import { createSelector } from '@reduxjs/toolkit'
import type { ReduxState } from 'src/store/types'
import type { HabaneroJackpot } from './types'

const getHabaneroJackpots = (state: ReduxState) => {
  return state.habaneroJackpot.jackpots
}

export const getHabaneroJackpotsSelector = createSelector(getHabaneroJackpots, (jackpots) => jackpots)

export const getRaceHabaneroJackpotsSelector = createSelector(getHabaneroJackpots, (jackpots) => {
  const raceJackpot = jackpots.find((el) => el.level === 'Race')

  return raceJackpot || ({} as HabaneroJackpot)
})

export const getRaceHabaneroJackpotsByGameCodeSelector = createSelector(
  getRaceHabaneroJackpotsSelector,
  (_: ReduxState, code: string) => code,
  (jackpot, code) => {
    return jackpot.games?.includes(code) ? jackpot.amount : null
  }
)

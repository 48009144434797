import { useRouter } from 'app/context/navigation'
import classes from './styles.module.scss'
import { useAppDispatch } from 'store/hooks'
import { openShopHandler } from 'store/modules/shop/actions'
import Button from 'components/Button'
import { useTranslation } from 'app/context/translations'

type Props = {
  isPromotionsPage?: boolean
}

export default function OffersNotAvailable({ isPromotionsPage }: Props) {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const t = useTranslation()

  return (
    <>
      {!isPromotionsPage && <div className={classes.titleOffersFallback} />}
      <div className={classes.noOffers}>
        <span className='mt-typography-body2'>
          {t('common.special_offers_not_available') || 'Special offers not available'}
        </span>
        {isPromotionsPage && (
          <Button
            data-test='common-header-buy-button'
            onClick={() => dispatch(openShopHandler({ isMobile: false, routerPush: router.push }))}
            variant='contained'
            size='small'
            className={classes.button}
          >
            {t('common.get_coins')}
          </Button>
        )}
      </div>
    </>
  )
}

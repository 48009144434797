import type {
  GameProductShort,
  GameProduct,
  SwimlaneCategoryStyle,
  TagsType,
} from '@patrianna/shared-patrianna-types/store/GameModule'
import ProductTileTags from 'components/ProductTileTags'

type Props = {
  game: GameProductShort | GameProduct
  swimlaneStyle?: SwimlaneCategoryStyle
}

export default function TilesTopBar({ game, swimlaneStyle }: Props) {
  return <ProductTileTags tags={game.tags as TagsType[] & 'HJR'} tagSize={swimlaneStyle} />
}

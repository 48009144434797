'use client'

import type { ReactNode } from 'react'
import cx from 'classnames'
import BackButton from 'src/components/BackButton'
import Page from 'src/components/Page'
import classes from './styles.module.scss'
import { useRouter } from 'app/context/navigation'

type Props = {
  children: ReactNode
  sticky?: boolean
}

function TitleWithBackButton(props: Props) {
  const router = useRouter()

  return (
    <Page rootClassName={classes.backButtonWrapper} sticky={props.sticky}>
      <BackButton className={classes.backButton} handler={router.back} />
      <h2 className={cx('mt-typography-h5', 'mt-typography-alignCenter')}>{props.children}</h2>
    </Page>
  )
}

export default TitleWithBackButton

import { useLayoutEffect, useState } from 'react'

export default function useIsMobileApp() {
  const [isMobileApp, setIsMobileApp] = useState<boolean>(false)

  useLayoutEffect(() => {
    setIsMobileApp(!!window.ReactNativeWebView)
  }, [])

  return isMobileApp
}

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useTranslation } from 'context/translationContext'
import { getSweepstakeJackpotOptInSelector, isAccountJackpotInfoLoading } from 'store/modules/jackpots/selectors'
import { openDialog } from 'store/modules/dialog/actions'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import Button from 'components/Button'
import classes from './styles.module.scss'
import { yieldToMainCallback } from '@patrianna/shared-utils'

export type Props = {
  content: string
}

export default function JackpotOptInButton(props: Props) {
  const dispatch = useAppDispatch()
  const t = useTranslation()
  const isScEnabled = useAppSelector(sweepstakeEnabledSelector)
  const isOptedIn = useAppSelector(getSweepstakeJackpotOptInSelector)
  const accountJackpotInfoLoading = useAppSelector(isAccountJackpotInfoLoading)

  const onOptInClick = () => {
    yieldToMainCallback(() => {
      dispatch(openDialog({ modalName: 'JACKPOTS_SWEEPSTAKE_INFO_DIALOG' }))
    })
  }

  if (!isScEnabled) {
    return null
  }

  return (
    <div className={classes.optInButtonWrapper}>
      <div className={classes.text} dangerouslySetInnerHTML={{ __html: props.content }} />
      {isOptedIn ? (
        <Button
          disabled={accountJackpotInfoLoading}
          loading={accountJackpotInfoLoading}
          variant='outlined'
          className={classes.optOutButton}
          onClick={onOptInClick}
        >
          {t('common.opt_out')}
        </Button>
      ) : (
        <Button
          disabled={accountJackpotInfoLoading}
          loading={accountJackpotInfoLoading}
          variant='contained'
          className={classes.optInButton}
          onClick={onOptInClick}
        >
          {t('common.opt_in')}
        </Button>
      )}
    </div>
  )
}

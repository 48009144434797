import { useMemo } from 'react'
import type { ReactElement } from 'react'
import type { GameProduct } from '@patrianna/shared-patrianna-types/store/GameModule'
import ProductTile from 'src/components/ProductTile'
import classes from '../styles.module.scss'
import useGetProducts from '../hooks/useGetProducts'

export type Props = {
  gameCodes: string
}

export default function GamesByIdContainer(props: Props) {
  const allGames = useGetProducts()

  const filteredGameTiles = useMemo(() => {
    return allGames.reduce((acc: ReactElement[], item: GameProduct) => {
      if (props.gameCodes?.includes(item.code)) {
        acc.push(<ProductTile game={item} key={item.code} />)
      }

      return acc
    }, [])
  }, [allGames, props.gameCodes])

  return <div className={classes.tilesContainer}>{filteredGameTiles}</div>
}

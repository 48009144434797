import BackButton from 'src/components/BackButton'
import classes from './stlyles.module.scss'
import { useRouter } from 'app/context/navigation'

type Props = {
  title: string
}

function PromotionTitleWithBackButton(props: Props) {
  const router = useRouter()

  return (
    <section className={classes.titleWrapper}>
      <BackButton className={classes.backButton} handler={router.back} />
      <span>{props.title}</span>
    </section>
  )
}

export default PromotionTitleWithBackButton

import type { ProductModes } from '@patrianna/shared-patrianna-types/store/GameModule'
import classes from './ProductTileModeFrame.module.scss'

type Props = {
  mode: ProductModes
}

export default function ProductTileModeBottomFrame({ mode }: Props) {
  const gameModeFrameImage =
    mode === 'sweepstake'
      ? `${process.env.IMG_ORIGIN}/product-tile-mode/sc-bottom.png`
      : mode === 'gold'
      ? `${process.env.IMG_ORIGIN}/product-tile-mode/gc-bottom.png`
      : null

  if (!gameModeFrameImage) {
    return null
  }

  return <img className={classes.gameModeFrame} src={gameModeFrameImage} alt={'game mode bottom frame'} />
}

'use client'

import { useRouter } from 'app/context/navigation'
import { Builder } from '@builder.io/react'
import classes from './styles.module.scss'
import { useTranslation } from 'context/translationContext'
import { RenderBuilderContent } from 'app/components/RenderBuilderContent'
import type { BuilderContent } from '@patrianna/shared-patrianna-types/builder'
import cx from 'classnames'

type Props = {
  content: BuilderContent
  model: string
  isBuilderTextContent?: boolean
}

export default function BuilderTextContent({ content, model, isBuilderTextContent }: Props) {
  const router = useRouter()
  const t = useTranslation()

  const handleLinkClick = (e: any) => {
    e.preventDefault()
    if (e.target.closest('a')) {
      const url = e.target.closest('a').attributes.href.value
      router.push(url)
    }
  }

  return (
    <div className={cx(classes.root, { [classes.isBuilder]: isBuilderTextContent })} onClick={handleLinkClick}>
      {content || Builder.isPreviewing ? (
        <RenderBuilderContent content={content} model={model} />
      ) : (
        <h1>{t('common.not_found_title')}</h1>
      )}
    </div>
  )
}

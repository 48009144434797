import dynamic from 'next/dynamic'
import cx from 'classnames'
import { useAppSelector } from 'store/hooks'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import {
  getGoldJackpotTotalsSelector,
  getSweepstakeJackpotTotalsSelector,
  getWinsJackpotSelector,
} from 'store/modules/jackpots/selectors'
import classes from './styles.module.scss'
import useSyncJackpotTotals from 'hooks/useSyncJackpotTotals'
import { B2Carousel } from '@patrianna/b2-carousel/src'
import { useMediaQuery } from '@patrianna/shared-hooks'

const JackpotListItem = dynamic(() => import('./components/JackpotListItem'))
const JackpotListItemV2 = dynamic(() => import('./components/JackpotListItemV2'))

const isJackpotEnabled = process.env.JACKPOT_ENABLED

type Props = {
  hideFooter?: boolean
  smallImages?: boolean
  className?: string
  isCompactHeight?: boolean
}

export default function Jackpots({ hideFooter, smallImages, className, isCompactHeight = false }: Props) {
  const Component = isCompactHeight ? JackpotListItemV2 : JackpotListItem

  const isMobile = useMediaQuery('sm')

  const goldJackpotTotals = useAppSelector(getGoldJackpotTotalsSelector)
  const sweepStakeJackpotTotals = useAppSelector(getSweepstakeJackpotTotalsSelector)
  const scEnabled = useAppSelector(sweepstakeEnabledSelector)
  const jackpotWins = useAppSelector(getWinsJackpotSelector)

  useSyncJackpotTotals()

  if (!isJackpotEnabled) {
    return null
  }

  return (
    <B2Carousel
      className={classes.root}
      classNameContainer={cx(classes.container, isCompactHeight && classes.compact)}
      showPagination={isMobile && isCompactHeight && scEnabled}
    >
      {scEnabled && (
        <Component
          hideFooter={hideFooter}
          smallImages={smallImages}
          jackpotTotal={sweepStakeJackpotTotals}
          jackpotWins={jackpotWins.SC}
          className={className}
          sc
        />
      )}
      <Component
        hideFooter={hideFooter}
        smallImages={smallImages}
        jackpotTotal={goldJackpotTotals}
        jackpotWins={jackpotWins.GC}
        className={className}
      />
    </B2Carousel>
  )
}

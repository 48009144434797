import cx from 'classnames'
import { SnackbarContent } from '@patrianna/core-components'
import AnimatedOverlay from 'dialogs/components/AnimatedOverlay'
import classes from './ConfirmationMessageSnackbar.module.scss'
import ConfirmationMessageContent from './ConfirmationMessageContent'
import { useAppDispatch } from 'src/store/hooks'
import { closeSnackbar } from 'src/store/modules/snackbar/actions'
import useIsBottomTabsBar from 'hooks/useIsBottomTabsBar'

type ConfirmationMessageSnackbarProps = {
  className?: string
  message?: string
}

function ConfirmationMessageSnackbar(props: ConfirmationMessageSnackbarProps) {
  const { message, className } = props
  const dispatch = useAppDispatch()
  const isBottomTabsBar = useIsBottomTabsBar()

  const handleOverlayClick = () => {
    dispatch(closeSnackbar())
  }

  return (
    <AnimatedOverlay mobileDirection='center' desktopDirection='center' overlayHandler={handleOverlayClick}>
      <SnackbarContent
        className={cx(className, classes.snackbarContent, { [classes.additionalMargin]: isBottomTabsBar })}
        message={<ConfirmationMessageContent message={message} />}
      />
    </AnimatedOverlay>
  )
}

export default ConfirmationMessageSnackbar

import type { SwimlaneCategory, GameProductShort } from '@patrianna/shared-patrianna-types/store/GameModule'

export const getGamesByCategoryCode = (categories: SwimlaneCategory[], categoryCode: string) => {
  const category = categories.find(({ code }) => code === categoryCode)

  return category?.products || []
}

export const getCategoryProducts = (categories: SwimlaneCategory[]) => {
  return categories.reduce((acc, category) => {
    acc.push(...category.products)

    return acc
  }, [])
}

export const hasGameWithCode = (games: GameProductShort[], gameCode: string) =>
  !!games.find(({ code }) => code === gameCode)

export const isGameAlreadyPlayed = (recentlyPlayedGames: GameProductShort[], gameProductCode: string) =>
  recentlyPlayedGames.some(({ code }) => code === gameProductCode)

import { useCallback } from 'react'
import {
  getCurrencyIntlNumberFormatFn,
  getCurrencySymbol as getCurrencySymbolFn,
} from '@patrianna/shared-utils/currency'
import { intlnumberFormat } from '@patrianna/shared-utils/numbers'
import { Currency } from '@patrianna-payments/shared-store/redeem/types/Currencies'

import { useAppSelector } from 'store/hooks'
import { getCurrencyDataFromUserPersonalInfoSelector } from 'store/modules/user/selectors'

export const useCurrencyFormatter = () => {
  const { currency, country } = useAppSelector(getCurrencyDataFromUserPersonalInfoSelector)

  const getCurrencySymbol = useCallback(
    (currencyProp = currency) => getCurrencySymbolFn(country, currencyProp) || '$',
    [country, currency]
  )

  const getCurrencyWithGameCurrencySymbol = useCallback(
    (currencyProp = currency) => {
      if (currencyProp === Currency.SC || currencyProp === Currency.GC) {
        return currencyProp
      }

      return getCurrencySymbol(currencyProp)
    },
    [currency, getCurrencySymbol]
  )

  const getCurrencyIntlNumberFormat = useCallback(
    (options?: Intl.NumberFormatOptions) => {
      return getCurrencyIntlNumberFormatFn(country, { ...options, currency })
    },
    [country, currency]
  )

  const currencyFormatter = (value: number, options?: Intl.NumberFormatOptions) => {
    return getCurrencyIntlNumberFormat(options).format(value)
  }

  const currencyFormatterWithGameCurrency = (
    { amount, currency: currencyProp = currency }: { amount: number; currency?: string },
    options?: Intl.NumberFormatOptions
  ) => {
    if (currencyProp === Currency.SC || currencyProp === Currency.GC) {
      const formattedAmount = intlnumberFormat(amount || 0, 'en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        ...options,
      })

      return `${currencyProp} ${formattedAmount}`
    }

    return currencyFormatter(amount, { ...options, currency: currencyProp })
  }

  return { currencyFormatter, getCurrencySymbol, currencyFormatterWithGameCurrency, getCurrencyWithGameCurrencySymbol }
}

'use client'

import type { BuilderContent } from '@patrianna/shared-patrianna-types/builder'

import RegisteredBuilderCustomComponents from 'components/BuildersCustomComponents'
import BuilderTextContent from 'components/BuilderTextContent'
import Page from 'components/Page'
import TitleWithBackButton from 'containers/TitleWithBackButton'

// register custom component to builder
RegisteredBuilderCustomComponents()

type Props = {
  content: BuilderContent
}

export function BuilderTextPageContent({ content }: Props) {
  return (
    <>
      <TitleWithBackButton sticky>{content?.data?.titleContent || content?.data?.title}</TitleWithBackButton>
      <Page>
        <BuilderTextContent content={content} model={process.env.BUILDER_PAGE_MODEL} isBuilderTextContent />
      </Page>
    </>
  )
}

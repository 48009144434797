import { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import classes from './styles.module.scss'
import layoutConstants from 'config/layoutConstants.json'

export type Props = {
  title: string
  content: string
}

function PromotionTermsAccordion(props: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const accordionTitle = useRef(null)

  const onClick = () => {
    setIsOpen((isContentVisible) => !isContentVisible)
  }

  const scrollTopTitle = () => {
    if (!accordionTitle.current) return
    // @ts-ignore
    const y = accordionTitle.current.getBoundingClientRect().top + window.scrollY - layoutConstants.headerHeight
    window.scroll({
      top: y,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    if (isOpen) {
      const timeoutID = setTimeout(scrollTopTitle, 300)

      return () => clearTimeout(timeoutID)
    }
  }, [isOpen])

  return (
    <div className={classes.accordionContainer}>
      <div ref={accordionTitle} className={cx(classes.accordionTitle, isOpen && classes.show)} onClick={onClick}>
        {props.title}
        <img
          className={isOpen ? classes.toggle_up : classes.toggle_down}
          src={`${process.env.IMG_ORIGIN}/menu/down.png`}
          alt='accordion arrow down'
        />
      </div>
      <div className={cx(classes.accordionHolder, isOpen && classes.showContent)}>
        {/* eslint-disable-next-line react/no-danger */}
        <div className={classes.accordionContent} dangerouslySetInnerHTML={{ __html: props.content }} />
      </div>
    </div>
  )
}

export default PromotionTermsAccordion

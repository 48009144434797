import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { CategoryGamesModule } from 'store/types/CategoryGamesModule'
import { addFavoriteGameToStore, removeFavoriteGameFromStore } from 'store/modules/categoryGames/actions'
import type {
  GameProductShort,
  GameProduct,
  SwimlaneCategory,
} from '@patrianna/shared-patrianna-types/store/GameModule'
import { fetchCategoryGames } from './actions'
import { getGamesByCategoryCode, isGameAlreadyPlayed } from './utils'

const initialState: CategoryGamesModule = {
  favoriteGames: [],
  recentlyPlayedGames: [],
  recommendedGames: [],
  isSuccess: false,
}

export const categoryGamesSlice = createSlice({
  name: 'categoryGames',
  initialState,
  reducers: {
    clearCategoryGames: () => initialState,
    addOrUpdateRecentlyPlayedGameInStore: (state, action: PayloadAction<{ gameProduct: GameProduct }>) => {
      if (isGameAlreadyPlayed(state.recentlyPlayedGames, action.payload.gameProduct.code)) {
        // [FYI] if game has already been played, we remove it from previous position and put on top of the list
        state.recentlyPlayedGames = state.recentlyPlayedGames.filter(
          ({ code }) => code !== action.payload.gameProduct.code
        )
      }
      state.recentlyPlayedGames?.unshift(action.payload.gameProduct)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addFavoriteGameToStore, (state, action: PayloadAction<GameProductShort>) => {
      state.favoriteGames?.unshift(action.payload)
    })
    builder.addCase(removeFavoriteGameFromStore, (state, action: PayloadAction<string>) => {
      const filteredGames = state.favoriteGames?.filter(({ code }) => code !== action.payload)

      if (filteredGames) {
        state.favoriteGames = filteredGames
      }
    })
    builder.addCase(fetchCategoryGames.pending, (state) => {
      state.isSuccess = false
    })
    builder.addCase(fetchCategoryGames.fulfilled, (state, action: PayloadAction<Array<SwimlaneCategory>>) => {
      state.isSuccess = true
      state.favoriteGames = getGamesByCategoryCode(action.payload, 'favourite')
      state.recentlyPlayedGames = getGamesByCategoryCode(action.payload, 'last_played')
      state.recommendedGames = getGamesByCategoryCode(action.payload, 'recommend')
    })
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof categoryGamesSlice>>
export const {
  actions: { clearCategoryGames, addOrUpdateRecentlyPlayedGameInStore },
} = categoryGamesSlice

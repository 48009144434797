'use client'

import type { GameProductShort, GameProduct } from '@patrianna/shared-patrianna-types/store/GameModule'
import PragmaticJackpot from 'components/PragmaticJackpot'
import HabaneroJackpot from 'components/HabaneroJackpot'
import ProductTileModeBottomFrame from 'components/ProductTile/components/ProductTileModeFrame/ProductTileModeBottomFrame'
import classes from './TilesBottomBar.module.scss'

type Props = {
  game: GameProductShort | GameProduct
}

export default function TilesBottomBar({ game }: Props) {
  return (
    <>
      <PragmaticJackpot className={classes.amountPragmaticJackpot} gameProduct={game} />
      <HabaneroJackpot className={classes.amountPragmaticJackpot} gameProduct={game} />
      <ProductTileModeBottomFrame mode={game.mode} />
    </>
  )
}

import { type MouseEvent, useCallback } from 'react'
import { useAuth } from 'app/context/auth'
import { useTranslation } from 'app/context/translations'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { addFavoriteGame, isFavoriteGameSelector, removeFavoriteGame } from 'store/modules/categoryGames'
import type { UseFavoriteButtonContract } from 'containers/FavoriteButton/FavoriteButton.types'
import { openSnackbar } from 'store/modules/snackbar/actions'
import ConfirmationMessageSnackbar from '../../ConfirmationMessageSnackbar'

export const useFavoriteButton: UseFavoriteButtonContract = (gameProduct) => {
  const { isLoggedIn } = useAuth()
  const t = useTranslation()
  const isFavorite = useAppSelector((state) => isFavoriteGameSelector(state, gameProduct?.code))
  const dispatch = useAppDispatch()

  const handleSnackbar = (message: string) => {
    dispatch(
      openSnackbar({
        message: () => <ConfirmationMessageSnackbar message={message} />,
        positionVertical: 'bottom',
        positionHorizontal: 'center',
        autoHide: 5000,
      })
    )
  }

  const onFavoriteClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()
      if (isFavorite) {
        dispatch(removeFavoriteGame(gameProduct.code))
        handleSnackbar('common.game_was_removed')
      } else {
        dispatch(addFavoriteGame(gameProduct))
        handleSnackbar('common.game_was_added')
      }
    },
    [isFavorite, gameProduct]
  )

  const shouldNotRenderBtn = !gameProduct || !isLoggedIn
  const favoriteBtnTitle = isFavorite ? t('common.favorited') : t('common.add_to_favourites')
  const favoriteIconPathPart = isFavorite ? 'favourites' : 'favourites_active'
  const favoriteIconPath = `${process.env.IMG_ORIGIN}/common/${favoriteIconPathPart}.svg`

  return {
    shouldNotRenderBtn,
    favoriteBtnTitle,
    favoriteIconPath,
    onFavoriteClick,
  }
}

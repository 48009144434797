export { clearCategoryGames, addOrUpdateRecentlyPlayedGameInStore } from 'store/modules/categoryGames/slice'
export { addFavoriteGame, removeFavoriteGame, fetchCategoryGames } from 'store/modules/categoryGames/actions'
export {
  getFavoriteGamesSelector,
  isFavoriteGameSelector,
  getRecentlyPlayedGames,
  isRecentlyPlayedGameSelector,
  isMostRecentlyPlayedGameSelector,
} from 'store/modules/categoryGames/selectors'
export { getGamesByCategoryCode, getCategoryProducts } from 'store/modules/categoryGames/utils'

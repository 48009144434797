import { splitImageUrl } from 'src/utils/splitImageUrl'
import images from 'temp/images.json'
import { ImageType } from '../Picture.types'

interface GeneratePictureByTypeArgs {
  imageType: ImageType
  src: string
}

export function generatePictureByType({ imageType, src }: GeneratePictureByTypeArgs) {
  const currentImageType = images.types.find((t) => t.type === imageType)
  const { sizes, srcSetSizes, width, height } = currentImageType
  const { baseURL } = splitImageUrl(src)

  let webp = ''

  function generateSrcSetSizes(format: string) {
    return srcSetSizes.map((size) => `${baseURL}/${size}.${format} ${size.split('x')[0]}w`).join(', ')
  }

  switch (imageType) {
    case ImageType.BANNER:
    case ImageType.TILE:
    case ImageType.SEARCH:
    case ImageType.SCREENSHOT:
    case ImageType.GAME_DESCRIPTION:
    case ImageType.PROMO_PREVIEW:
    case ImageType.PROMO_HIGHLIGHTS:
      webp = generateSrcSetSizes('webp')
      break
    default:
      break
  }

  return {
    fallback: src,
    width,
    height,
    sizes,
    webp,
  }
}

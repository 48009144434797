import { useAppSelector } from 'store/hooks'
import { getSpecialOffers } from 'store/modules/shop/selectors'
import classes from './styles.module.scss'
import SpecialOffers from '../SpecialOffers'
import GoldCoinGenerator from 'components/GoldCoinGeneratorSection'

type Props = {
  title: string
}

export default function SpecialOffersForBuilder({ title }: Props) {
  const offers = useAppSelector(getSpecialOffers)
  if (!offers.length) {
    return null
  }

  return (
    <GoldCoinGenerator>
      <div className={classes.wrapper}>
        <h6 className={classes.title}>{title}</h6>
        <SpecialOffers feature='in_promo' />
      </div>
    </GoldCoinGenerator>
  )
}
